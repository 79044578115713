import { FC, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import classNames from "classnames";
import { observer } from "mobx-react";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { debugColumns } from "elements/debugColumn/debugColumn";
import { Format } from "elements/format/format";
import { useForecast } from "models/project/fact/forecast/forecast";
import { VoronoiGrid } from "services/back/injectionPrediction/voronoi";

import { DRow, InjectionSlice } from "./injectionSliceModel";

import cn from "./sliceTable.module.less";

const COLUMNS: ColumnRaw<DRow>[] = [
  ...debugColumns<DRow>([
    {
      dataKey: "id",
      title: "ID",
      width: 80,
    },
  ]),
  {
    key: "index",
    title: "No.пп",
    isSticky: true,
    width: 54,
    render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
  },
  {
    key: "expand",
    title: <div />,
    isSticky: true,
    width: { min: 32, max: 32, competitiveness: 1 },
    render: (_, tableItem) => (tableItem.expand !== undefined ? <ExpandButton expand={tableItem.expand} /> : <div />),
  },
  {
    dataKey: "injWellTitle",
    title: "Нагнетательная скважина",
    isSticky: true,
    width: { min: 140, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "injMineTitle",
    title: "Куст нагнетательной скважины",
    width: { min: 170, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "injProducingObjectTitle",
    title: "Объект разработки",
    width: { min: 100, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "injCapacity",
    title: "Приемистость (Qз м³/сут)",
    width: { min: 120, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "prodWellTitle",
    title: "Добывающая скважина",
    width: { min: 120, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "prodMineTitle",
    title: "Куст добывающей скважины",
    width: { min: 150, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "oilCoeff",
    title: "Коэф-т нефти",
    width: { min: 80, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "liquidRate",
    title: "Дебит жидкости (Qж м³/сут)",
    width: { min: 140, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "liquidRateWithCoeff",
    title: "Дебит жидкости (с учетом коэф-т нефти), м³/сут",
    width: { min: 210, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "oilRate",
    title: "Дебит нефти (Qн т/сут)",
    width: { min: 110, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "oilRateWithCoeff",
    title: "Дебит нефти (с учетом коэф-т нефти), т/сут",
    width: { min: 180, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "compensation",
    title: "Компенсация, %",
    width: 150,
    render: (value) => value && <Format>{value}</Format>,
  },
];

const InjectionSliceTable: FC<{ voronoi: VoronoiGrid }> = observer(({ voronoi }) => {
  const forecast = useForecast()!;
  const store = useMemo(() => new InjectionSlice(forecast, voronoi), [forecast, voronoi]);
  const model = useMemo(
    () =>
      new TableModel(
        COLUMNS,
        store,
        {
          onRow: ({ indexPath }) => ({
            className: classNames({ [cn.tableRowPrimary]: indexPath.length === 1 }),
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [store]
  );
  return (
    <TableContextProvider value={model}>
      <CsvSaver filename="Прогноз закачки. Срез по нагнетательным скважинам" exportArray={() => model.export()}>
        <Widget headerClassName={cn.tableHeader} className={cn.table} />
      </CsvSaver>
    </TableContextProvider>
  );
});

export { InjectionSliceTable };
