import { Dayjs } from "dayjs";

import { UOM } from "elements/uom";
import { TechSummaryAxisData } from "features/techSummaryChart/techSummaryChart";
import { global } from "models/global";
import { aggregateByDate } from "models/project/fact/production/aggregate";
import { Production, ProductionDatum } from "models/project/fact/production/production";
import { Well } from "models/project/fact/well/well";
import { transposeArrayObject } from "utils/itertools";
import { Range } from "utils/range";

import { defaultYearDatum, Param, PARAMS, YearDatum } from "./constants";

async function* zipAggregate(wells: Well[], productionStore: Production): AsyncGenerator<[Dayjs, YearDatum]> {
  const productions = wells.map((w) => productionStore.wholeWellData(w.id));
  const decemberProductions = wells.map((w) => productionStore.wholeWellData(w.id, true));

  const fondGen = aggregateByDate(aggregateFond, decemberProductions);
  const aggregatedGen = aggregateByDate(aggFunc, productions);

  let fondIt = fondGen.next();
  let aggIt = aggregatedGen.next();
  while (!fondIt.done && !aggIt.done) {
    const [year, datum] = aggIt.value;
    const [, fond] = fondIt.value;

    datum.miningFond = fond.miningFond;
    datum.injectionFond = fond.injectionFond;

    await new Promise((resolve) => setTimeout(resolve, 10));
    yield [year, datum];

    fondIt = fondGen.next();
    aggIt = aggregatedGen.next();
  }
}

async function aggregate(yearsRange: Range, wells: Well[], productionStore: Production): Promise<YearDatum[]> {
  const data = yearsRange.array.fill(null).map(() => defaultYearDatum(null));
  const accum = {
    oil: 0,
    liquid: 0,
    waterInj: 0,
  };
  for await (const [year, datum] of zipAggregate(wells, productionStore)) {
    const curYear = year.year();
    accum.oil += datum.oilProd ?? 0;
    accum.liquid += datum.liquidProd ?? 0;
    accum.waterInj += datum.waterInj ?? 0;

    if (!yearsRange.includes(curYear)) {
      continue;
    }

    datum.accumOilProd = accum.oil;
    datum.accumLiquidProd = accum.liquid;
    datum.accumWaterInj = accum.waterInj;

    datum.accumInjectCompensation = accum.liquid && accum.waterInj / accum.liquid;

    data[yearsRange.id(curYear)] = datum;
  }

  return data;
}

function yearDatumsToAxes(datums: YearDatum[]): { lines: TechSummaryAxisData[]; bars: TechSummaryAxisData } {
  const transposed = transposeArrayObject(datums, defaultYearDatum(null))!;

  const by_unit = new Map<string, Param[]>();
  PARAMS.forEach((param) => {
    by_unit.get(param.measure.unit ?? "unknown")?.push(param) ?? by_unit.set(param.measure.unit ?? "unknown", [param]);
  });

  const BAR_UNIT = new UOM(12, 1, global.uomResolver).unit;

  const bars: TechSummaryAxisData = {
    unit: "Скважины, шт",
    side: "right",
    dataSet: (by_unit.get(BAR_UNIT ?? "скв") ?? []).map((param) => ({
      y: transposed[param.key],
      title: param.title,
    })),
  };
  by_unit.delete(BAR_UNIT ?? "скв");

  const lines: TechSummaryAxisData[] = [];
  for (const [unit, params] of by_unit) {
    lines.push({
      unit: unit,
      side: 2 * lines.length < by_unit.size ? "left" : "right",
      dataSet: params.map((param) => ({
        y: transposed[param.key],
        title: param.title,
      })),
    });
  }

  return { lines, bars };
}

const aggFunc = (datums: ProductionDatum[]): YearDatum => {
  const result = defaultYearDatum(0);

  for (const datum of datums) {
    result.oilProd += datum.oil_prod ?? 0;
    result.liquidProd += datum.liquid_prod ?? 0;
    result.waterInj += datum.water_inj ?? 0;
    result.prodDays += datum.prod_days ?? 0;
    result.injDays += datum.inj_days ?? 0;
  }
  result.waterCut = (1 - result.oilProd / result.liquidProd) * 100 || 0;
  result.oilRate = (result.oilProd * 1000) / result.prodDays || 0;
  result.liquidRate = (result.liquidProd * 1000) / result.prodDays || 0;
  result.injectionCapacity = (result.waterInj * 1000) / result.injDays || 0;
  result.injectCompensation = result.liquidProd && result.waterInj / result.liquidProd;

  return result;
};

const aggregateFond = (datums: ProductionDatum[]): Pick<YearDatum, "miningFond" | "injectionFond"> => {
  let { miningFond, injectionFond } = defaultYearDatum(0);
  for (const datum of datums) {
    if ((datum.inj_days ?? 0) > 0 && (datum.water_inj ?? 0) > 0) {
      ++injectionFond;
    }
    if ((datum.prod_days ?? 0) > 0 && (datum.oil_prod ?? 0) > 0) {
      ++miningFond;
    }
  }
  return { miningFond, injectionFond };
};

export { aggregate, yearDatumsToAxes };
