import { useCallback, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import classNames from "classnames";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumns } from "elements/debugColumn/debugColumn";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { type DRow, InputParams } from "models/project/fact/forecast/injPrediction/inputParams";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { ReactComponent as AddManyPeriodsIcon } from "./icons/addManyPeriods.svg";
import { ReactComponent as AddPeriodIcon } from "./icons/addPeriod.svg";
import { ReactComponent as CompenstaionCoefIcon } from "./icons/compensationCoeff.svg";
import { ReactComponent as ExtendBaseInjectionIcon } from "./icons/extendBaseInjection.svg";
import { useMultiPeriodModal } from "./multiPeriodModal/multiPeriodModal";
import { usePeriodModal } from "./periodModal/periodModal";
import { useProducingObjectModal } from "./producingObjectForm/producingObjectModal";
import { ToggleIntersectedButton } from "./toggleInstersectedButton/toggleIntersectedButton";

import cn from "./inputParams.module.less";

const PERIOD_STATUS_NAME = {
  prod: "Добывающая",
  inj: "Нагнетательная",
  idle: "Бездействие",
} as const;

const useColumns = () => {
  const fact = useFact()!;
  const { from, to } = fact.forecastDateRange;

  return useMemo<ColumnRaw<DRow>[]>(() => {
    return [
      ...debugColumns<DRow>([
        {
          dataKey: "wellId",
          title: "ID скважины",
          width: 80,
        },
        {
          dataKey: "gtmId",
          title: "ID ГТМ",
          width: 80,
        },
      ]),
      {
        key: "index",
        title: "No.пп",
        width: 54,
        isSticky: true,
        render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
      },
      {
        key: "expand",
        title: <div />,
        isSticky: true,
        width: { min: 32, max: 32, competitiveness: 1 },
        render: (_, tableItem) => (tableItem.expand !== undefined ? <ExpandButton expand={tableItem.expand} /> : <div />),
      },
      {
        dataKey: "wellTitle",
        title: "Скважина",
        isSticky: true,
        width: { min: 100, max: 400, competitiveness: 2 },
        render: (value) => <Format>{value}</Format>,
      },
      {
        dataKey: "mineTitle",
        title: "Куст",
        width: { min: 100, max: 200, competitiveness: 1 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "eventTitle",
        title: "Мероприятие",
        width: { min: 190, max: 270, competitiveness: 1 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "licenseRegionTitle",
        title: "ЛУ",
        width: { min: 110, max: 400, competitiveness: 2 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "start",
        title: "Дата начала",
        width: 130,
        render: (date: Dayjs | undefined, { value, update }) =>
          date && (
            <MonthPicker
              value={date}
              minDate={from}
              maxDate={to}
              onChange={(date) => update?.("start", date.date(1))}
              disabled={value?.periodStatus !== "inj"}
              variant="borderless"
            />
          ),
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "end",
        title: "Дата окончания",
        width: 130,
        render: (date: Dayjs | undefined, { value, update }) =>
          date && (
            <MonthPicker
              value={date}
              minDate={from}
              maxDate={to}
              onChange={(date) => update?.("end", date.date(1))}
              disabled={value?.periodStatus !== "inj"}
              variant="borderless"
            />
          ),
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "periodStatus",
        title: "Назначение",
        width: 160,
        render: (status?: "prod" | "inj" | "idle") => status && PERIOD_STATUS_NAME[status],
      },
      {
        dataKey: "producingObjectId",
        title: "Объект разработки",
        width: 180,
        render: (prodId: number | undefined, { value, update }) =>
          value?.periodStatus !== "idle" && value?.producingObjectsResolver !== undefined ? (
            <SelectStorable
              values={[prodId ?? null, undefined]}
              setValues={(id) => update?.("producingObjectId", id)}
              store={value.producingObjectsResolver}
              disabled={value?.periodStatus !== "inj"}
            />
          ) : null,
      },
      {
        title: "Залежь",
        dataKey: "stratumId",
        width: 180,
        render: (id: number | null | undefined, { value }) => {
          if (value?.periodStatus === "idle" || id === null || id === undefined) {
            return null;
          }
          const stratum = fact.stratums.at(id);
          return (
            <Ellipsis limit={30} position="mid">
              <Format>{id !== null ? stratum?.title : null}</Format>
            </Ellipsis>
          );
        },
      },
      {
        title: "Коэф-т компенсации",
        dataKey: "compensationCoefficient",
        width: 160,
        render: (coef: number | null | undefined, { update, expand }) => {
          if (expand !== undefined || coef === null) {
            return null;
          }
          if (coef === undefined) {
            return <Format>{coef}</Format>;
          }
          return <LazyInputNumber value={coef} onUpdate={(newValue: number | null) => update?.("compensationCoefficient", newValue ?? 0)} min={0} />;
        },
      },
      {
        title: <div />,
        key: "sticky",
        width: 38,
        render: (_, { expand, value }) => {
          if (expand !== undefined || value?.periodStatus !== "inj") {
            return null;
          }
          return <DeleteButton onClick={value?.remove} />;
        },
        onCell: () => ({ style: { position: "sticky", right: 0 } }),
      },
    ];
  }, [from, to, fact.stratums]);
};

const InjectionPredictionInputParams = observer(() => {
  const forecast = useForecast()!;
  const projectContext = useProjectContext();

  const tree = projectContext.wellsTree;

  const columns = useColumns();
  const store = useMemo(() => new InputParams(forecast, tree), [forecast, tree]);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ value, indexPath }) => ({
            className: classNames(
              {
                [cn.tableRowPrimary]: indexPath.length === 1,
                [cn.invalidRow]: value?.isIntersected,
              },
              value?.periodStatus && cn[`${value.periodStatus}Row`]
            ),
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );

  const producingObjectModal = useProducingObjectModal();
  const onProducingObjectModal = useCallback(() => {
    return producingObjectModal().then((data) => store.propagateCompensationCoefficients(data));
  }, [producingObjectModal, store]);

  const periodModal = usePeriodModal(store.disabledDate, store.producingObjectIds);
  const onPeriodModal = useCallback(() => {
    return periodModal(null).then(({ well, producingObject, range }) => {
      const [from, to] = range;
      store.addPeriod(well, producingObject, from.date(1), to.date(1));
    });
  }, [periodModal, store]);

  const multiPeriodModal = useMultiPeriodModal();
  const onMultiPeriodModal = useCallback(() => {
    return multiPeriodModal().then((settings) => store.addManyPeriods(settings));
  }, [multiPeriodModal, store]);

  const project = useProject()!;
  const edit = getUserPermission(project, forecast)["tech"];
  const editTitle = (title?: string) => (edit ? title : "Для выполнения действия недостаточно полномочий вашей роли в проекте");

  return (
    <>
      <PageFrameTitlePortal
        model={store}
        submitting={store.isSaving}
        onSave={store.save}
        submitCustomTooltip={{ onNotValid: "Данные содержат пересекающиеся периоды. Сохранение невозможно" }}
        permissionSection="tech"
      >
        <ToggleIntersectedButton hasIntersected={!store.isValid} onlyIntersected={() => store.onlyIntersected()} reset={() => store.resetFilters()} />
        <ToolbarButton
          tooltip={{
            title: editTitle("Добавить период нагнетания"),
          }}
          icon={<AddPeriodIcon />}
          onClick={onPeriodModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Задать периоды нагнетания"),
          }}
          icon={<AddManyPeriodsIcon />}
          onClick={onMultiPeriodModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Продлить режим нагнетания БФ"),
          }}
          icon={<ExtendBaseInjectionIcon />}
          onClick={store.expandBaseInjection}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Задать коэффициент компенсации"),
          }}
          icon={<CompenstaionCoefIcon />}
          onClick={onProducingObjectModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{ title: editTitle("Удалить периоды нагнетания") }}
          popconfirm={{
            title: "Удаление периодов нагнетания",
            description: "Удалить все периоды нагнетания для выбранных скважин?",
            onConfirm: store.removeSelectedWellsInjection,
            onCancel: () => "",
            okText: "Да",
            cancelText: "Нет",
          }}
          icon={<DeleteOutlined />}
          disabled={!edit.value}
          danger
        />
        <ToolbarButton
          tooltip={{
            title: editTitle(!store.isValid ? "Данные содержат пересекающиеся периоды. Запуск расчета невозможен" : undefined),
          }}
          type="primary"
          disabled={!store.isValid || forecast.compensationCoefficients.isLoading || !edit.value}
          loading={forecast.injectionResults.isCalculating}
          onClick={() => store.saveCompensation().then(() => forecast.injectionResults.calculate())}
        >
          Запустить расчет
        </ToolbarButton>
      </PageFrameTitlePortal>
      <div className={cn.root}>
        <TableContextProvider value={model}>
          <Widget headerClassName={cn.tableHeader} className={cn.table} />
        </TableContextProvider>
      </div>
    </>
  );
});

export { InjectionPredictionInputParams };
