import { UOM } from "elements/uom";
import { global } from "models/global";

const SUMMARY_FIELDS = [
  "prodDays",
  "injDays",
  "oilProd",
  "oilRate",
  "accumOilProd",
  "liquidProd",
  "liquidRate",
  "accumLiquidProd",
  "waterCut",
  "waterInj",
  "accumWaterInj",
  "injectCompensation",
  "accumInjectCompensation",
  "injectionCapacity",
  "miningFond",
  "injectionFond",
] as const;

type YearDatumInternal<T extends number | null> = Record<(typeof SUMMARY_FIELDS)[number], T>;
type YearDatum = YearDatumInternal<number | null>;

function defaultYearDatum<T extends number | null>(fillValue: T): YearDatumInternal<T extends null ? (number | null) : number> {
  return Object.fromEntries(SUMMARY_FIELDS.map((k) => [k, fillValue])) as unknown as YearDatumInternal<T extends null ? (number | null) : number>;
}

type Param = {
  key: keyof YearDatum;
  title: string;
  measure: UOM;
};

const PARAMS: Param[] = [
  {
    key: "oilProd",
    title: "Добыча нефти",
    measure: new UOM(5, 4, global.uomResolver),
  },
  {
    key: "oilRate",
    title: "Среднесуточный дебит нефти",
    measure: new UOM(6, 1, global.uomResolver),
  },
  {
    key: "accumOilProd",
    title: "Накопленная добыча нефти",
    measure: new UOM(5, 4, global.uomResolver),
  },
  {
    key: "liquidProd",
    title: "Добыча жидкости",
    measure: new UOM(7, 6, global.uomResolver),
  },
  {
    key: "liquidRate",
    title: "Среднесуточный дебит жидкости",
    measure: new UOM(8, 1, global.uomResolver),
  },
  {
    key: "accumLiquidProd",
    title: "Накопленная добыча жидкости",
    measure: new UOM(7, 6, global.uomResolver),
  },
  {
    key: "waterCut",
    title: "Обводненность",
    measure: new UOM(3, 2, global.uomResolver),
  },
  {
    key: "waterInj",
    title: "Закачка воды",
    measure: new UOM(7, 6, global.uomResolver),
  },
  {
    key: "injectionCapacity",
    title: "Среднесуточная приемистость",
    measure: new UOM(8, 1, global.uomResolver),
  },
  {
    key: "accumWaterInj",
    title: "Накопленная закачка",
    measure: new UOM(7, 6, global.uomResolver),
  },
  {
    key: "injectCompensation",
    title: "Компенсация отбора закачкой",
    measure: new UOM(3, 1, global.uomResolver),
  },
  {
    key: "accumInjectCompensation",
    title: "Накопленная компенсация отбора закачкой",
    measure: new UOM(3, 1, global.uomResolver),
  },
  {
    key: "miningFond",
    title: "Фонд действующих добывающих скважин",
    measure: new UOM(12, 1, global.uomResolver),
  },
  {
    key: "injectionFond",
    title: "Фонд нагнетательных скважин",
    measure: new UOM(12, 1, global.uomResolver),
  },
];

export type { Param, YearDatum };
export { defaultYearDatum, PARAMS, SUMMARY_FIELDS };
