import { useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnStore } from "@okopok/components/Table/models/columns/store";
import { Button, Dropdown, Input, Select, Tooltip } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";

import { ReactComponent as AddColumnIcon } from "./icons/nastyushkino.svg";
import { CustomColumns, CustomColumnType } from "./customColumns";

import cn from "./customColumnsSettings.module.less";

type CustomColumnSettingsProps = {
  columnsStore: ColumnStore<any>;
  customColumns: CustomColumns;
};

type CustomColumnRowProps = {
  title?: string;
  type?: CustomColumnType;
  onAdd: (title: string, type: CustomColumnType) => void;
  onDelete: (title: string) => void;
  isTitleUnique: (title: string) => boolean;
};

const CUSTOM_COLUMN_TYPE_OPTIONS = [
  { value: "string" as const, label: "Строка" },
  { value: "number" as const, label: "Число" },
  { value: "date" as const, label: "Дата" },
];

const CustomColumnRow = (props: CustomColumnRowProps) => {
  const [title, setTitle] = useState(props.title);
  const [type, setType] = useState(props.type);
  const [isEditable, setIsEditable] = useState(props.title === undefined);

  const onClick = () => {
    if (isEditable) {
      if (title === undefined || type === undefined) {
        console.error(`cant add: title=${title}, type=${type}`);
        return;
      }
      props.onAdd(title, type);
    } else {
      if (title === undefined) {
        console.error(`cant delete: title=${title}`);
        return;
      }
      props.onDelete(title);
    }
    setIsEditable(!isEditable);
  };

  return (
    <>
      <Input
        disabled={!isEditable}
        status={title === undefined || props.isTitleUnique(title) ? "" : "error"}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Select disabled={!isEditable} value={type} options={CUSTOM_COLUMN_TYPE_OPTIONS} onSelect={(selected) => setType(selected)} />
      <Button
        disabled={isEditable ? !title || !props.isTitleUnique(title) || type === undefined : false}
        onClick={onClick}
        icon={isEditable ? <PlusOutlined /> : <CloseOutlined style={{ color: "firebrick" }} />}
      />
    </>
  );
};

function addColumn(title: string, type: CustomColumnType, { customColumns, columnsStore }: CustomColumnSettingsProps) {
  const columnRaw = customColumns.addColumn(title, type);
  columnsStore.addColumn(columnRaw);
}

function deleteColumn(title: string, { customColumns, columnsStore }: CustomColumnSettingsProps) {
  const columnKey = customColumns.removeColumn(title);
  columnsStore.removeColumn(columnKey);
}

const CustomColumnSettings = observer(({ customColumns, columnsStore }: CustomColumnSettingsProps) => {
  const onAdd = (title: string, type: CustomColumnType) => {
    addColumn(title, type, { customColumns, columnsStore });
  };
  const onDelete = (title: string) => {
    deleteColumn(title, { customColumns, columnsStore });
  };
  const isTitleUnique = (title: string) => !customColumns.hasColumn(title);
  return (
    <div className={cn.container}>
      <div className={cn.columnsList}>
        <p>Название</p>
        <p>Тип значения</p>
      </div>
      <div className={cn.columnsList}>
        {customColumns.meta.map(([title, type]) => {
          return <CustomColumnRow key={title} title={title} type={type} isTitleUnique={isTitleUnique} onAdd={onAdd} onDelete={onDelete} />;
        })}
        <CustomColumnRow key={`empty-${customColumns.meta.length}`} isTitleUnique={isTitleUnique} onAdd={onAdd} onDelete={onDelete} />
      </div>
    </div>
  );
});

const CustomColumnsButton = (props: CustomColumnSettingsProps) => {
  return (
    <Dropdown destroyPopupOnHide dropdownRender={() => <CustomColumnSettings {...props} />} trigger={["click"]}>
      <Tooltip title="Пользовательские колонки">
        <Button type="text" icon={<Icon width="20" height="20" content={<AddColumnIcon />} viewBox="0 0 24 24" />} />
      </Tooltip>
    </Dropdown>
  );
};

export { CustomColumnsButton, CustomColumnSettings };
