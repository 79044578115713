/* eslint-disable react-hooks/rules-of-hooks */
import { useRef } from "react";
import { Alert, Collapse, CollapseProps, Typography } from "antd";
import { observer } from "mobx-react";

import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { RecoverableResourcesExcelType } from "services/back/wells";
import { conditionallyArr } from "utils/conditionally";

import cn from "./importResourcesErrorModal.module.less";

type ErrorsType = {
  data: RecoverableResourcesExcelType[];
  errors: { error: string; type: string; uuid: string }[];
  error?: string;
};

const getItems = (errors: ErrorsType["errors"]): CollapseProps["items"] => {
  const notInBase = errors.filter((el) => el.type === "notInBase");
  const notValidFormatValue = errors.filter((el) => el.type === "notValidFormatValue");
  const notInFile = errors.filter((el) => el.type === "notInFile");

  return [
    ...conditionallyArr(!!notInFile.length, {
      key: "1",
      label: "В импортируемом файле отсутствуют некоторые скважины из базового фонда",
      children: (
        <div className={cn.alerts}>
          {notInFile.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
    ...conditionallyArr(!!notValidFormatValue.length, {
      key: "2",
      label: "Значения некоторых скважин представлены в неверном формате",
      children: (
        <div className={cn.alerts}>
          {notValidFormatValue.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
    ...conditionallyArr(!!notInBase.length, {
      key: "3",
      label: "В базовом фонде отсутствуют некоторые скважины",
      children: (
        <div className={cn.alerts}>
          {notInBase.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
  ];
};

const ModalContent: ModelContentComponentType<RecoverableResourcesExcelType[], ErrorsType> = observer(({ dataRef, setLoading, setOnAccept }) => {
  const items = getItems(dataRef.current.errors);

  const onFinish = async () => {
    setLoading(true);
    return { result: true, data: dataRef.current.data };
  };

  setOnAccept(onFinish);

  if (dataRef.current.error) {
    return <Alert showIcon message={dataRef.current.error} type="error" />;
  }

  return (
    <div className={cn.importResourceModalWrap}>
      <Typography.Title className={cn.importResourceModalTitle} level={2}>
        При импорте данных были обнаружены признаки того, что в данных может быть ошибка, продолжить?
      </Typography.Title>
      <Collapse items={items} />
    </div>
  );
});

const useImportResourcesErrorModal = (): ((data: ErrorsType) => Promise<RecoverableResourcesExcelType[] | null>) => {
  const showModal = useModal<any, ErrorsType>();

  const val = useRef<ErrorsType>({ data: [], errors: [] });
  return async (data: ErrorsType) => {
    val.current = data;
    return showModal(ModalContent, val, "Ошибка импорта ОИЗ", null).catch(() => null);
  };
};

export { useImportResourcesErrorModal };
