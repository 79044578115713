import { FC } from "react";
import { Button, ColorPicker } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Icon } from "elements/icon/icon";
import { Eye } from "elements/icons/eye";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { ReactComponent as ArrowIcon } from "../../../icons/arrow.svg";

import cn from "./legend.module.less";

const Legend: FC = observer(() => {
  const model = useTechForecastModel();
  const chart = model.currentForecast.chart;
  return (
    <ul className={cn.items}>
      {chart.channelGroups.map(({ title, key, toggleVisible, isVisible, isPartiallyVisible, channels }) => (
        <li key={key}>
          <div className={cn.title}>
            {key === "other" && (
              <Button
                type="text"
                size="small"
                onClick={chart.toggleUngrouped}
                icon={<Icon content={<ArrowIcon />} className={classNames(cn.icon, chart.hideUngrouped && cn.collapsed)} viewBox="0 0 8 4" />}
              />
            )}
            <Button type="text" size="small" icon={<Eye isVisible={isVisible} isPartiallyVisible={isPartiallyVisible} />} onClick={toggleVisible}>
              {title}
            </Button>
          </div>
          <ol className={classNames(cn.channels, chart.hideUngrouped && key === "other" && cn.hiddenList)}>
            {channels.map(({ key, title, axis, color, isVisible, toggleVisible, setColor }) => (
              <li key={key}>
                <ColorPicker className={cn.colorPicker} size="small" value={color} onChange={setColor} />
                <Button size="small" type="text" icon={<Eye isVisible={isVisible} />} onClick={toggleVisible}>
                  <span>
                    <Ellipsis limit={30} position="mid">
                      {[title, axis].filter(Boolean).join(", ")}
                    </Ellipsis>
                  </span>
                </Button>
              </li>
            ))}
          </ol>
        </li>
      ))}
    </ul>
  );
});

export { Legend };
